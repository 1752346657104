.pricing-table-container {
    padding: 20px;
    
    display: flex;
    justify-content: center;
}

.pricing-table {
    width: 100%;
    max-width: 800px;
    border-collapse: collapse;
    
    border: 1px solid #1e1e1e;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.pricing-table th, .pricing-table td {
    border: 2px solid #ff914d15;
    padding: 15px;
    text-align: center;
}

.pricing-table th {
    
    font-weight: bold;
}

.pricing-table td {
    font-size: 1em;
}

.icon {
    margin-left: 10px;
}

.tick {
    color: green;
}

.cross {
    color: red;
}
