.home-body{
    position: absolute;
    top: 50px;
    background-color: rgb(0, 0, 0);
    color: white;
    width: -webkit-fill-available;
}
.contact{
    /* border-bottom: 1px solid #303030; */
    border-top: 1px solid #303030;
}


  .pds-items-box{
    display: flex;
    align-items: center;
    gap: 5px;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100px;
    cursor: pointer;

  }
  
  .container_{
    /* padding-right: 190px; */
    /* padding-left: 190px; */
    width: 100%;
    max-width: 100%;
    
  }
  .video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  .blink-logo {
    animation: blink 1s infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
  }
  .blink-logo-container{
    position: relative;
    background-color: #000;
    width: 100%;
    height: 100vh;
    text-align: center;
    vertical-align: middle;
  }

  
 
  
  .item {
    flex: 0 0 auto; /* Items take their content width */
    text-align: -webkit-center;
  }
  
  .item img {
    max-width: 100%; /* Ensure images resize properly */
    width: 180px; /* Fixed width of each item */
    height: auto; /* Maintain aspect ratio */
  }
  