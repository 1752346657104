

.top-nav-bar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

html,
body {
  background-color: #f9fafb;
}

.top-nav-bar li {
  float: left;
  
}

.top-nav-bar {
  
  box-shadow: rgba(0, 0, 0, 0.4) 1px 1px 7px;
  padding-right: 20px;
  padding-left: 20px;
  position: fixed;
  width: 100%;
  z-index: 5;
  background-color: #000;
  overflow: hidden;
  height: 70px;
  /* border-bottom: 0.1px solid #00ff00; */
  
}


.nav-menu-item{
  color: #ff914d;
  cursor: pointer;
  
}
.nav-at-top{

  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding-right: 20px;
  position: absolute;
  width: 96%;
  z-index: 5;

}

.top-nav-bar .nav-menu-item a{
  display: block;
    padding: 0px 20px;
    text-decoration: none;
    /* text-transform: uppercase; */
    font-size:20px;
    font-weight: 500;
    color: #ff914d;
    height: 50px;
    align-content: center;
}

/* .top-nav-bar .nav-menu-item:hover {
  color: #fff;

} */

.btn {
  margin-right: 15px;
  border-radius: 50px;

  width: 100px;
}

.nav-dropdown {

  display: none;
  border-radius: 10px;

}

.top-nav-bar .nav-menu-item:hover .nav-dropdown {

  display: flex;
  flex-direction: column;
  position: fixed;
  width: 200px;
  z-index: 1;
  transition: visibility 0.5s ease;
  text-align: left;
  border: none;
  padding: 0px;
  border-radius: 0px;
  box-shadow: none;
  margin: 0px;
  background: #fff;



}
.mob-row{
  height: 70px;
}
.dropdown-items a {
  border-bottom: 1px solid #ededed;
  font-size: 12px;
  background-color: #000;
  vertical-align:bottom;
  padding: 0;
}

.dropdown-items a:hover {
  
  color: white;
  
  /* padding: 0; */
}


@media only screen and (max-width: 900px) {

  .top-nav-bar li a {

    padding: 13px 10px;

  }

  .mob-row{
    height: 50px;
  }
  .btn {
    margin-right: 5px;
    border-radius: 50px;
    font-size: 14px;
    width: 70px;
  }



  .logo {

    text-align: center;

  }
}

/* Responsive Nav Bar */

@media only screen and (max-width: 800px) {

  .mob-row{
    height: 50px;
  }
}


.sidebar {
  position: fixed;
  top: 0;
  right: -300px; /* Start with the sidebar hidden outside the viewport */
  height: 100%;
  width: 300px;
  background-color: black;
  transition: right 0.3s ease;
  padding: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  color: #ff914d;
  z-index: 99;
}

.sidebar.open {
  right: 0; /* Show the sidebar by moving it to the right */
  z-index: 99;
}

